<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div>
      <v-img :src="getIMG(imgurl)"></v-img>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    imgurl: "",
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Profil",
        disabled: true,
        href: "",
      },
      {
        text: "Struktur Organisasi",
        disabled: false,
        href: "/profil/struktur",
      },
    ],
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/profilGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "images/struktur-default.jpg";
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/struktur")
        .then((res) => {
          this.imgurl = res.data.data.profil_file;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
